import React from 'react';
import MetaIcon from '../../assets/Integration/meta.svg';
import MetaImage from '../../assets/Integration/metaImage.svg';
import NewTabNavigateIcon from '../../assets/Integration/navigate.svg';
import IntegrationHeader from '../HeadingInfoPanel';
import TextBlog from './TextBlog';

import GoogleSheet from '../../assets/Integration/googleSheets.svg';
import Jira from '../../assets/Integration/jira.svg';
import Word from '../../assets/Integration/word.svg';

const MetaDoc = () => {
  let cards = [
    {
        "name":"Google sheets",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btn": "Connected",
        "logo": GoogleSheet,
        "learnMore": "Learn more",
        
    },
    {
        "name":"Jira",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btn": "Connect",
        "learnMore": "Learn more",
        "logo": Jira

    },
    {
        "name":"Microsoft word",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btn": "Connect",
        "learnMore": "Learn more",
        "logo": Word
    },

]

  return (

    <>
      <IntegrationHeader
        isBreadCrumb={"Integration"}
        heading={"Meta Integration"}
        description={"Create and save your secret API Keys."}
        ButtonName={"Create Connection"}
        />

      <div className="box-container p-5">
        <div className="row p-0">
          <div className="col-lg-6 p-0">
            <div className='d-flex column gap-4 text-justify'>
              <div className="txt-blog">
                <TextBlog
                  heading={"Meta"}
                  paragraph={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. Egestas elit eget nisi justo tincidunt.Id eu sit ut fringilla bibendum tristique etiam. "}
                  button={"Connect"}
                />
              </div>
              <div className="txt-sub-blog mt-4">
                <TextBlog
                  heading={"WHAT IS META?"}
                  paragraph={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. Egestas elit eget nisi justo tincidunt.Id eu sit ut fringilla bibendum tristique etiam. "}
                />
              </div>
              <div className="txt-sub-blog mt-2">
                <TextBlog
                  heading={"WHAT IS META?"}
                  paragraph={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. Egestas elit eget nisi justo tincidunt.Id eu sit ut fringilla bibendum tristique etiam. "}
                />
              </div>
              <div className="txt-sub-blog mt-2">
                <TextBlog
                  heading={"WHAT IS META?"}
                  paragraph={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. Egestas elit eget nisi justo tincidunt.Id eu sit ut fringilla bibendum tristique etiam. Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. "}
                  buttletPoints={["Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. ", "Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. ", "Egestas cum egestas magna bibendum turpis eget sed. "]}
                />
              </div>
            </div>
            <div className="txt-sub-blog mt-5">
              <TextBlog 
                heading={"HOW TO INTEGRATE META"}
                paragraph={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. Egestas elit eget nisi justo tincidunt.Id eu sit ut fringilla bibendum tristique etiam. Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. "}
                
              
              />
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="d-flex justify-content-end">
              <img className='w-75' src={MetaImage} alt="" />
            </div>
            <div className="right-info-card">
              <div className="docs-card-heading d-flex align-items-center gap-2">
                <img src={MetaIcon} alt="meta icon" />
                <h1 className='m-0'>Meta</h1>
              </div>
              <div className='docs-card-hdesc'>
                <h2>Built by</h2>
                <p>Mark zuckerberg</p>
              </div>
              <div className='docs-card-hdesc'>
                <h2>Category</h2>
                <p>Finance & Technology</p>
              </div>
              <div className='docs-card-hdesc'>
                <h2>Usecases</h2>
                <div className='d-flex align-items-center w-100 flex-wrap gap-2'>
                  <span className='badge'>Finance</span>
                  <span className='badge'>Authentication</span>
                  <span className='badge'>Tech</span>
                </div>
              </div>
              <div className='docs-card-hdesc'>
                <h2>Total downloads</h2>
                <div className='d-flex gap-2'>
                  <span className='count-integration'>
                    +25,000
                  </span>
                <p>Integrations</p>
                </div>
              </div>

            </div>
          </div>
          <div className="mt-3 d-flex gap-3 mb-5">
          <div className="docs-card">
            <div className='d-flex justify-content-between'>
              <h6 className='fs-6 fw-bold lh-base'>See our Documentation</h6>
              <img src={NewTabNavigateIcon} alt="" />
            </div>
            <p>Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum.</p>
          </div>
          <div className="docs-card">
            <div className='d-flex justify-content-between'>
              <h6 className='fs-6 fw-bold lh-base'>How to implement or integrate Meta</h6>
              <img src={NewTabNavigateIcon} alt="" />
            </div>
            <p>Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum.</p>
          </div>
        </div>

        </div>
      </div>
    </>

    )
}

export default MetaDoc