import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceCard, ServiceCardList } from './Cards';

const InteCards = ({name, cards, cardClass}) => {

    const navigate = useNavigate();
    const [cardsData, setcardsData] = useState([]);
    useEffect(() => {
        let res;
        if (name != "All integrations") {
            res = cards.filter((item) => item?.name == name)
            setcardsData(res);
        } else {
            setcardsData(cards)
        }
    }, [cards]);

    const handleClick = (item) => { 
        // console.log(item);
        if (item.name == "Add Custom API keys") {
            // setcardsData(item)
            navigate('/home/users/integration/CreateSecretKey/custom')
        } else {
            navigate(`${'/home/users/integration/CreateSecretKey'}`)

        }
     }
    return (
        <div className='card-container'>
            <div className="card-center">
                {
                    cardsData?.length > 0 && cardsData?.map((item) => (
                        cardClass ? (
                           <ServiceCard btnHandleClick={handleClick} item={item} ></ServiceCard>
                        ) : (
                            <ServiceCardList item={item} btnHandleClick={handleClick}></ServiceCardList>                        
                        )
                    ))
                }
                {
                    !cardsData?.length > 0 && <h1>No data found</h1>
                }            
            </div>
        </div>
    );
}

export default InteCards