import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.css";

function TelSelect(props) {

    const {
        className,
        inputClass,
        value,
        status,
        placeholder,
        onChange,
        label,
        labelfor,
        disabled,
        labelId,
        name,
        id,
        key,
        searchPlaceholder,
        helperText,
        enableSearch,
    } = props

    return (
        <React.Fragment>
                <PhoneInput
                    disableSearchIcon="true"
                    {...props}
                />
        </React.Fragment>
    );
}

export default TelSelect;
