import React from 'react'
import { Button } from 'react-bootstrap'
import './button.css';

function Buttons(props) {

  const {
    variant,
    value,
    className,
    imgLeft,
    imgRight,
    onClick,
    loader
  } = props;

  return (
    <Button {...props} className={`dsButton btn-ripple ${className}`} variant={variant} onClick={onClick}>
      {loader ? <>
        {<div class="loader-container">
          <div class="loader"></div>
        </div>
        }
      </> : <>
        {imgLeft ? <span className='leftImg'>{imgLeft}</span> : ''}
        {value}
        {imgRight ? <span className='rightImg'>{imgRight}</span> : ''}
      </>
      }
    </Button>
  )
}

export default Buttons