import React, { useEffect, useState } from "react";
import Logo from "../../assets/idflow-logo.svg";
import Google from "../../assets/signUp/google-icon.svg";
import Outlook from "../../assets/signUp/outlook.svg";
import Facebook from "../../assets/signUp/facebook_outline.svg";
import M2Plogo from "../../assets/signUp/m2p.svg";
import { useNavigate } from "react-router-dom";
import { sendEmail } from "../../api/users";
import { setEmail } from "../../api/common";
import CustomHeader from "../core/Header";
import CustomInput from "../core/InputField";
import CustomFooter from "../core/Footer";
import CustomButton from "../core/Button";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import { notification } from "antd";
import "../../styles/global.css";
export default function SignUp() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Some thing went wrong",
      placement: "bottomRight",
      duration: 2,
      className: "toast-error-message",
    });
  };
  const showSuccessMessage = () => {
    api.success({
      description: "Email sent Successfully!.Please check your email.",
      placement: "bottomRight",
      duration: 2,
      className: "toast-success-message",
    });
  };
  const handleSendEmail = () => {
    setLoader(true);
    const domainRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/i;
    if (userEmail) {
      if (domainRegex.test(userEmail)) {
        setEmail(userEmail);
        sendEmail(userEmail)
          .then((res) => {
            if (res) {
              showSuccessMessage();
              console.log({ res });
            } else {
              showErrorMessage();
            }
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            // showErrorMessage(err?.message);
            showErrorMessage("User already exist");
            // console.log({ err });
          });
      } else {
        setLoader(false);
        showErrorMessage("Enter valid email");
      }
    } else {
      setLoader(false);
      showErrorMessage("Please Enter Email");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendEmail();
    }
  };
  return (
    <div className="main-signup">
      {contextHolder}
      <IDFlowHeader />
      <div className="body-signup">
        <div className="signup-sec">
          <div>
            <CustomHeader
              title={"Sign up for free trial"}
              subDescription={
                "In just a few steps, you can start building custom workflows!"
              }
            />
            <CustomInput
              label={"Email ID"}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Enter your email"
              handleKeyPress={handleKeyPress}
            />
            {/* <div className="checkbox-div">
              <input
                onChange={(e) => {
                  setIsChecked(!isChecked);
                }}
                type="checkbox"
              />
              <label className="labelstyle">
                I Agree to the{" "}
                <span className="signup-span">Terms and Conditions</span>
              </label>
            </div> */}
          </div>
          <div>
            <CustomButton
              handleFunction={handleSendEmail}
              title={"Sign Up"}
              loader={loader}
            />
            <p className="btn-desc">
              Do you have an account?{" "}
              <span onClick={() => navigate("/user/login")}>Sign in</span>
            </p>
            {/* <div className="linediv">
              <p className="line" />
              <p className="linetext">or Sign up with</p>
              <p className="line" />
            </div>
            <div className="btn-div">
              <button className="icon-btn">
                <img src={Google} alt="icon" />
              </button>
              <button className="icon-btn">
                <img src={Outlook} alt="icon" />
              </button>
              <button className="icon-btn">
                <img src={Facebook} alt="icon" />
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
