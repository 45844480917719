import { useFormik } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import Button from "../FormComponents/Button/Buttons";
import Inputs from '../FormComponents/Input/Inputs';
import IntegrationHeader from "../HeadingInfoPanel";

const CreateSecretKey = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeName = location.pathname;
  const isCustom = routeName.includes("custom");
  const isAny = routeName.includes("any");
  const currentNodeId = routeName.split("/custom/");
  const [Disable, setDisable] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    key: Yup.string().required(),
    value: Yup.string().required(),
    category: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues:{},
    // validationSchema: validationSchema,    

    onSubmit:async (values) => {
      setDisable(true)
      
      formik.resetForm();
      if (isCustom) {
        navigate(`/home/users/integration/apiKeyManager/custom`)
      } else {
        navigate(`/home/users/integration/apiKeyManager`)
      }
      
    },
  });

  return (
    <>
    <IntegrationHeader
      heading={"Create Secret Key"}
      description={"Create and save your secret API Keys."}
      SearchBar={false}
      SearchBarWidth={100}
      isBreadCrumb={"integration"}
    />
      <div className="box-container">
        <IntegrationHeader
          className={"bg-white"}
          hrLine={true}
        >
          <h1 style={{ fontSize: '20px'}}>{isCustom && "Create Secret Key" }</h1>
        </IntegrationHeader>
        <div className='cus-form mt-0 px-4'>
          {isCustom && (
            <div className='block-box'>
              <Inputs
                label="Category"
                htmlFor="Category"
                type="text"
                id="category"
                placeholder='Input'
                value={formik.values.category}
                onChange={formik.handleChange}
                error={formik.errors.category}
                // disabled={isDisable}
              />
          </div>
        
          )}
          <div className='block-box'>
            <Inputs
              label="Name"
              htmlFor="Name"
              type="text"
              id="name"
              placeholder='Input'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              // disabled={isDisable}
            />
          </div>
          <div className='block-box'>
            <Inputs
              label="Key"
              htmlFor="Key"
              type="text"
              id="key"
              placeholder='{{secret.mango}}'
              value={formik.values.key}
              onChange={formik.handleChange}
              error={formik.errors.key}
            />
          </div>
          <div className='block-box'>
            <Inputs
              label="Value"
              htmlFor="Value"
              type="textarea"
              id="value"
              className="textarea-input"
              placeholder='idflow.syntizen.com'
              value={formik.values.value}
              onChange={formik.handleChange}
              error={formik.errors.value}
            />
          </div>
          </div>
          <div className="saveEditButton px-4 pb-4">
            <Button variant="outlined" value="Cancel" onClick={() => navigate(`/home/users/integration`)} />
            <Button value="Create" onClick={formik.handleSubmit}/>
          </div>
      </div>
      </>
  )
}

export default CreateSecretKey;