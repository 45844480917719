import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchSortFilter from "../SearchFilterSort";
import {
  getAllUsers,
  getUserByID,
  updateUser as updateUserData,
} from "../../api/users";

import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllUserDetails from "../AllUserDetails";
import CustomButton from "../core/Button";
import { getAllWorkFlow } from "../../api/workflow";
import { getAllAccounts } from "../../api/accounts";
import { getAllGroups } from "../../api/groups";

const UsersScreen = () => {
  const navigate = useNavigate();
  const [switchModal, setSwitchModal] = useState(false);
  const [addNewUser, setAddNewUser] = useState(false);
  const [selected, setSelected] = useState({});
  const [state, setState] = useState([]);
  const [allFlows, setAllFlows] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [isMoreRecords, setIsMoreRecords] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortOption, setSortOption] = useState([
    "Newest to oldest",
    "oldest to Newest",
    "A-Z Ascending order",
    "Z-A Descending order",
  ]);
  //  const sortOptionList = ;

  const [updateUser, setUpdateUser] = useState({
    organization: selected?.organization,
    organization_name: selected?.organization_name,
    phone_number: {
      country_code: selected?.phone_number?.country_code,
      number: selected?.phone_number?.number,
    },

    email: selected?.email,
    full_name: selected?.full_name,
    image: selected?.image,
    service: selected?.service,
    disabled: selected?.disabled,
    is_admin: selected?.is_admin,
    otp: "0",
    permissions: {},
  });
  const showErrorMessage = (err) => {
    toast.error(err ? err : "Some thing went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const showSuccessMessage = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const filterFlowsByPermissions = (user, flows) => {
    let permittedFlowIds = [];
    let permissionsType;

    if (
      user.permissions &&
      user.permissions.flows &&
      user.permissions.flows.access
    ) {
      user.permissions.flows.access.forEach((flowId) => {
        permittedFlowIds.push(flowId);
      });
      permissionsType = user.permissions.flows.type;
    }
    let filteredFlows = flows.filter((flow) =>
      permittedFlowIds.includes(flow.id)
    );
    return { filteredFlows, permissionsType };
  };

  const filterGroupsByPermissions = (user, flows) => {
    let permittedFlowIds = [];
    let permissionsType;

    if (
      user.permissions &&
      user.permissions.groups &&
      user.permissions.groups.access
    ) {
      user.permissions.groups.access.forEach((flowId) => {
        permittedFlowIds.push(flowId);
      });
      permissionsType = user.permissions.groups.type;
    }
    let filteredFlows = flows.filter((flow) =>
      permittedFlowIds.includes(flow.id)
    );
    return { filteredFlows, permissionsType };
  };

  const filterAccountByPermissions = (user, flows) => {
    try {
      let permittedFlowIds = [];
      let permissionsType;
      if (
        user.permissions &&
        user.permissions.accounts &&
        user.permissions.accounts.access
      ) {
        user.permissions.accounts.access.forEach((flowId) => {
          permittedFlowIds.push(flowId);
        });
        permissionsType = user.permissions.accounts.type;
      }
      let filteredFlows = flows.filter((flow) =>
        permittedFlowIds.includes(flow.id)
      );
      return { filteredFlows, permissionsType };
    } catch (err) {
      console.log({ err });
    }
  };
  const handleGetUser = (id) => {
    setSwitchModal(false)
    getUserByID(id)
      .then((res) => {
        if (res) {
          let selectedData = { ...res };
  
          if (allFlows.length === 0 && allGroups.length === 0) {
            Promise.all([
              getAllWorkFlow("name"),
              getAllGroups("name"),
              getAllAccounts("name"),
            ])
              .then(([workflowRes, groups, accounts]) => {
                if (workflowRes) {
                  const workflowResp = workflowRes.map((item) => ({
                    id: item._id,
                    ...item,
                  }));
                  setAllFlows(workflowResp);
  
                  if (groups) {
                    const groupsp = groups.map((item) => ({
                      id: item._id,
                      ...item,
                    }));
                    setAllGroups(groupsp);
                    if (accounts) {
                      const accountsp = accounts.map((item) => ({
                        id: item._id,
                        ...item,
                      }));
                      setAllAccounts(accountsp);
  
                      const { filteredFlows, permissionsType } = filterAccountByPermissions(
                        res,
                        accountsp
                      );
  
                      selectedData = {
                        ...selectedData,
                        accounts: filteredFlows,
                        accountType: permissionsType,
                      };
                    }
                  }
                }
  
                const { filteredFlows: flowsFiltered, permissionsType: flowsType } =
                  filterFlowsByPermissions(res, allFlows);
                selectedData = {
                  ...selectedData,
                  flows: flowsFiltered,
                  flowType: flowsType,
                };
  
                const {
                  filteredFlows: groupsFiltered,
                  permissionsType: groupsType,
                } = filterGroupsByPermissions(res, allGroups);
                selectedData = {
                  ...selectedData,
                  groups: groupsFiltered,
                  groupType: groupsType,
                };
  
                setSelected(selectedData);
                setSwitchModal(false);
              })
              .catch((error) => {
                console.error("Error during API calls:", error);
              });
          } else {
            const { filteredFlows: flowsFiltered, permissionsType: flowsType } =
              filterFlowsByPermissions(res, allFlows);
            selectedData = {
              ...selectedData,
              flows: flowsFiltered,
              flowType: flowsType,
            };
  
            const {
              filteredFlows: accountsFiltered,
              permissionsType: accountsType,
            } = filterAccountByPermissions(res, allAccounts);
            selectedData = {
              ...selectedData,
              accounts: accountsFiltered,
              accountType: accountsType,
            };
  
            const {
              filteredFlows: groupsFiltered,
              permissionsType: groupsType,
            } = filterGroupsByPermissions(res, allGroups);
            selectedData = {
              ...selectedData,
              groups: groupsFiltered,
              groupType: groupsType,
            };
  
            setSelected(selectedData);
            setSwitchModal(false);
          }
        } else {
          showErrorMessage();
        }
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };
  

  const getAllUsersData = (update) => {
    getAllUsers()
      .then(async (res) => {
        if (res && res.length > 0) {
          if (update) {
            setState(res);
            setLoading(false);
          } else {
            setState(res);
            setSkip(skip + 10);
            await handleGetUser(res[0].id);
            setLoading(false);
          }
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllUsersData();
  }, []);

  const switchScreen = () => {
    setSwitchModal(!switchModal);
  };
  const handleUpdate = () => {
    updateUserData(selected.id, updateUser)
      .then((res) => {
        if (res) {
          setSelected(res);
          getAllUsersData("update");
          setSwitchModal(false);
        } else {
          showErrorMessage();
        }
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };

  const handleScroll = () => {
    if (loading) {
      return;
    }
    if (isMoreRecords === true) {
      setLoading(true);
      getAllUsers(10, skip, search, sortOrder, sortBy)
        .then((res) => {
          if (res && res.length > 0) {
            setState((prevState) => {
              const filteredRes = res.filter(
                (newRecord) =>
                  !prevState.some((oldRecord) => oldRecord.id === newRecord.id)
              );
              return prevState.concat(filteredRes);
            });
            if (res.length < 10) {
              setIsMoreRecords(false);
            }
            setLoading(false);
            setSkip(skip + 10);
          }
        })
        .catch((_err) => {
          setLoading(false);
        });
    }
  };

  const handleSearchEnter = (event) => {
    // searchGroups
    if (event.key === "Enter") {
      if (search.length > 0) {
        setLoading(false);
        getAllUsers(10, 0, search, sortOrder, sortBy)
          .then(async (res) => {
            if (res && res.length === 0) {
              showErrorMessage("No User found");
            } else {
              setState(res);
              await handleGetUser(res[0].id);
              setLoading(false);
              setIsMoreRecords(true);
              setSkip(10);
            }
          })
          .catch((_err) => {
            toast.error("Unable to fetch groups data");
            setLoading(false);
            setIsMoreRecords(true);
            setSkip(0);
          });
      } else {
        getAllUsers(10, 0, "", sortOrder, sortBy)
          .then(async (res) => {
            setState(res);
            await handleGetUser(res[0].id);
            setLoading(false);
            setIsMoreRecords(true);
            setSkip(10);
          })
          .catch((_err) => {
            toast.error("Unable to fetch groups data");
            setLoading(false);
            setIsMoreRecords(true);
            setSkip(0);
          });
      }
    }
  };
  const handleSort = (event) => {
    const value = event;
    let sortOrder, sortBy;
    // setSelectedOption(value);

    switch (value) {
      case "Newest to oldest":
        sortOrder = "desc";
        sortBy = "created_at";
        break;
      case "oldest to Newest":
        sortOrder = "asc";
        sortBy = "created_at";
        break;

      case "A-Z Ascending order":
        sortOrder = "asc";
        sortBy = "name";
        break;

      case "Z-A Descending order":
        sortOrder = "desc";
        sortBy = "name";
        break;

      default:
        sortOrder = "desc";
        sortBy = "created_at";
    }

    setSortOrder(sortOrder);
    setSortBy(sortBy);

    setLoading(true);
    getAllUsers(10, 0, search, sortOrder, sortBy)
      .then(async (res) => {
        setState(res);
        await handleGetUser(res[0].id);
        setLoading(false);
        setIsMoreRecords(true);
        setSkip(10);
      })
      .catch((_err) => {
        toast.error("Unable to fetch groups data");
        setLoading(false);
        setIsMoreRecords(true);
        setSkip(0);
      });
  };
  useEffect(() => {
    getAllUsers(sortOption);
  }, [sortOption]);
  return (
    <div className="builder-main">
      <ToastContainer />

      <div className="builder-heading">
        <div className="builder-main-heading">
          <p className="users-title">Users</p>

          <p className="users-title-desc">
            Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien
            scelerisque ac.
          </p>
        </div>
        {!addNewUser && (
          <CustomButton
            title={"Add new contact"}
            className={"builder-heading-btn"}
            handleFunction={() => {
              setSwitchModal(false);
              setAddNewUser(true);
            }}
          />
        )}
      </div>
      <SearchSortFilter
        onSearch={handleSearchEnter}
        setSearch={setSearch}
        search={search}
        sortOptions={sortOption}
        setSortOption={setSortOption}
        onSort={handleSort}
        width={"31.1%"}
      />
      <AllUserDetails
        handleGetUser={handleGetUser}
        selected={selected}
        state={state}
        switchModal={switchModal}
        switchScreen={switchScreen}
        handleUpdate={handleUpdate}
        setUpdateUser={setUpdateUser}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        setAddNewUser={setAddNewUser}
        addNewUser={addNewUser}
        setSelected={setSelected}
        getAllUsersData={getAllUsersData}
        allFlows={allFlows}
        handleScroll={handleScroll}
        isMoreRecords={isMoreRecords}
        loading={loading}
        allAccounts={allAccounts}
        allGroups={allGroups}
      />
    </div>
  );
};

export default UsersScreen;
