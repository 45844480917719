import React, { useEffect, useState, } from "react";
import axios from "axios";
import { useLocation,useNavigate } from 'react-router-dom';
export default function SocialPageCall() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
useEffect(()=>{
    console.log(params.get('token'),"segment connection")
    if(params.get('token')===null || params.get('token')===undefined){
      return  navigate("/user/login");
    }
    localStorage.setItem('accessToken',params.get('token'));
    localStorage.setItem('email',params.get('email'));
   return navigate("/home/explore");
},[]);    

  return <>
  </>
}
