import React, { useEffect, useState } from "react";
import Logo from "../../assets/idflow-logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import "../loginScreen/login.css";
import { checkUserRegister, login, resetPassword, updateUserPassword } from "../../api/users";
import { toast } from "react-toastify";
import CustomHeader from "../core/Header";
import CustomInput from "../core/InputField";
import CustomButton from "../core/Button";
import CustomFooter from "../core/Footer";
import { ForgetMsg } from "../../util/helper";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import { notification } from 'antd'
import '../../styles/global.css';

export default function PasswordScreen() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [tokenParams, setTokenParams] = useState("");
  const [validPassword, setValidPassword] = useState(false)
  const [api, contextHolder] = notification.useNotification();

  const [loginData, setLoginData] = useState({
    password: "",
    newPassword: "",
  });
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("e");
    const decode = window.atob(email);
    setEmailAddress(decode);
    const token = searchParams.get("Id");
    setTokenParams(email)
    if (email && token) {
      const body = {
        email,
        token,
      };
      checkUserRegister(body)
        .then((res) => {
          if (res && res.message === "verification successful") {
            showSuccessMessage("Email verified");
          } else {
            showErrorMessage(res?.message);
            setTimeout(() => {
              navigate("/user/signup");
            }, 2000);
          }
        })
        .catch((err) => {
          showErrorMessage(err?.response?.data?.message);
          setTimeout(() => {
            navigate("/user/signup");
          }, 2000);
        });
    }
  }, []);

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Something went wrong",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'

    })
  };

  const showSuccessMessage = (res) => {
    api.success({
      description: res ? res : "User created successfully! Please login.",
      placement: 'bottomRight',
      duration: 2,
      className: 'toast-success-message'
    })
  };
  const handleSignup = () => {
    setLoader(true);
    const value = validatePassword(loginData.password);
    setValidPassword(value)
    if (loginData.password === "" && loginData.newPassword) {
      setLoader(false);
      showErrorMessage("Please enter password");
    }
    else if (loginData.newPassword === "" && loginData.password) {
      setLoader(false);
      showErrorMessage("Please enter confirm password");
    }
    else if (loginData.password === '' && loginData.newPassword === '') {
      setLoader(false);
      showErrorMessage('Please fill all the fields')
    }
    else if (value) {
      if (loginData.password === loginData.newPassword) {
        const data = {
          email: emailAddress,
          password: loginData.password,
          token: tokenParams
        };
        updateUserPassword(data)
          .then((res) => {
            setLoader(false);
            if (res) {
              showSuccessMessage("Password set successfully for account");
              setTimeout(() => {
                navigate("/user/login")
              }, 5000)

            }
          })
          .catch((err) => {
            setLoader(false);
            showErrorMessage("unable to create user with ", err);
          });
      } else {
        setLoader(false);
        showErrorMessage("Password not matched");
      }
      // showSuccessMessage("Password updadted succesfuly");
    } else {
      setLoader(false);
      showErrorMessage(<ForgetMsg />)
    }
  };
  const validatePassword = (password) => {
    if (password.length < 8) {
      return false;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(password)) {
      return false;
    }

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    if (!uppercaseRegex.test(password) || !lowercaseRegex.test(password)) {
      return false;
    }
    const numberRegex = /\d/;
    if (!numberRegex.test(password)) {
      return false;
    }

    return true;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignup()
    }
  };
  return (
    <div className="main-login">
      <IDFlowHeader />
      <div className="body-login">
        <div className="login-sec">
          <div>
            <CustomHeader
              title={"Digital Onboarding Platform"}
              description={"Welcome back!"}
              subDescription={"Please set your password"}
            />
            <div className="form_group_input">
              <CustomInput
                label={"Password"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                setShowPassword={() => setShowPassword(!showPassword)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
            </div>
            <div className="form_group_input">
              <CustomInput
                label={"Confirm password"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    newPassword: e.target.value,
                  }))
                }
                placeholder="Re-enter your  password"
                type={showPassword1 ? "text" : "password"}
                setShowPassword={() => setShowPassword1(!showPassword1)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <CustomButton
            title={"Set Password"}
            handleFunction={handleSignup}
            loader={loader}

          />

        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
