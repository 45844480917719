export const checkFormValidation = ({ formDetails, username, showErrorMessage }) => {
    const missingFields = [];
    if (
        formDetails.organization === "" &&
        formDetails.organization_name === '' &&
        !formDetails.service &&
        !formDetails.phone_number.number &&
        !username.firstName &&
        !username.lastName) {
        showErrorMessage('Please fill all the fields')
    }
    else {
        if (!formDetails.organization_name) {
            missingFields.push("Organization Name");
        }
        if (!formDetails.organization) {
            missingFields.push("Organization")
        }
        if (!formDetails.service) {
            missingFields.push("Service");
        }
        if (!formDetails.phone_number.country_code || !formDetails.phone_number.number) {
            missingFields.push("Phone Number");
        }
        if (!username.firstName) {
            missingFields.push("First Name");
        }
        if (!username.lastName) {
            missingFields.push("Last Name");
        }
    }
    if (missingFields.length != 0) {
        const missingFieldsMessage = `Please fill in the following fields: ${missingFields.join(", ")}`;
        showErrorMessage(missingFieldsMessage);
    }
}