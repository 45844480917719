import React, { useState, useEffect, useRef } from "react";
import { getEmail, setEmail } from "../../api/common";
import { sendResetEmail } from "../../api/users";

const CountdownTimer = ({ showSuccessMessage, showErrorMessage, setOtp }) => {
  const minutes = 2;
  const [remainingTime, setRemainingTime] = useState(minutes * 60);
  const [loader, setLoader] = useState(false);

  const intervalRef = useRef(null);
  const email = getEmail();

  useEffect(() => {
    setRemainingTime(minutes * 60);
  }, [email]);

  useEffect(() => {
    if (remainingTime === 0) {
      clearInterval(intervalRef.current);
    }

    if (remainingTime > 0) {
      intervalRef.current = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [remainingTime]);

  const handleResendOtp = () => {
    setLoader(true);
    sendResetEmail(email)
      .then((res) => {
        if (res) {
          setLoader(false);
          setRemainingTime(minutes * 60);
          setOtp("");
          showSuccessMessage(
            "New OTP Resend to your mail Successfully. Please check your registered mail."
          );
        } else {
          setLoader(false);
          showErrorMessage();
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  const displayMinutes = Math.floor(remainingTime / 60);
  const displaySeconds = remainingTime % 60;

  return (
    <div>
      {remainingTime === 0 ? (
        <p className="otp-color-text" onClick={handleResendOtp}>
          {loader ? (
            <div class="loader-container" style={{ background: "none" }}>
              <div class="loader"></div>
            </div>
          ) : (
            "Resend OTP"
          )}
        </p>
      ) : (
        <p className="otp-color-text1">
          Resend OTP in {displayMinutes.toString().padStart(2, "0")}:
          {displaySeconds.toString().padStart(2, "0")}
        </p>
      )}
    </div>
  );
};

export default CountdownTimer;
