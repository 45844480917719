import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import CustomSelect from "../core/CustomSelect";
import CustomInput from "../core/InputField";
import CustomButton from "../core/Button";
import RmvIcon from "../../assets/userScreenSvg/remove-icon.svg";
import { createUser } from "../../api/users";
import PhoneInput from "react-phone-input-2";
import { createImage } from "../../api/accounts";

const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {};
};

export default function AddNewNumber({
  setAddNewUser,
  setSelected,
  getAllUsersData,
  showSuccessMessage,
  showErrorMessage,
  allFlows,
  allAccounts,
  allGroups,
}) {
  const [newAccount, setNewAccount] = useState({
    organization: "1",
    organization_name: "",
    phone_number: {
      country_code: 92,
      number: 0,
    },
    email: "",
    full_name: "",
    image: "",
    service: "",
    disabled: false,
    is_admin: false,
    otp: "0",
    permissions: {},
  });

  const [toggleIsActive, setToggleIsActive] = useState(false);
  const [state, setState] = useState(false);

  const handleChangeIcon = (event) => {
    if (event.target.files.length > 0) {
      const image = event.target.files[0];
      const formData = new FormData();
      formData.append("file", image);
      createImage(formData)
        .then((res) => {
          setNewAccount((prev) => ({ ...prev, image: res.id }));
        })
        .catch((error) => {
          showErrorMessage(error);
        });
     
    }
  };

  const countryCodeOptions = [91, 92];
  const [item, setItem] = useState([]);
  const [account, setAccont] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Read");
  const [typeAccounts, setTypeAccont] = useState("Read");
  const [typeGroups, setTypeGroup] = useState("Read");
  const dailCode = useRef();

  const [list, setList] = useState([
    {
      id: "1",
      name: "Flows",
      isActive: true,
    },
    {
      id: "2",
      name: "Accounts",
      isActive: false,
    },
    {
      id: "3",
      name: "Groups",
      isActive: false,
    },
    {
      id: "4",
      name: "Others",
      isActive: false,
    },
  ]);

  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      label: "Analytics",
      checked: false,
      key: "Analytics",
    },
    {
      id: 2,
      label: "Billing",
      checked: false,
      key: "billing",
    },
    {
      id: 3,
      label: "Logs",
      checked: false,
      key: "logs",
    },
    {
      id: 4,
      label: "Dashboard",
      checked: false,
      key: "dashboard",
    },
    {
      id: 5,
      label: "UI builder",
      checked: false,
      key: "ui_builder",
    },
    {
      id: 6,
      label: "Chat account",
      checked: false,
      key: "chat_account",
    },
    {
      id: 7,
      label: "Styles",
      checked: false,
      key: "styles",
    },
    {
      id: 8,
      label: "Reports",
      checked: false,
      key: "reports",
    },
    {
      id: 9,
      label: "Notifications",
      checked: false,
      key: "notifications",
    },
    {
      id: 10,
      label: "Node builder",
      checked: false,
      key: "node_builder",
    },
    {
      id: 11,
      label: "Zapier",
      checked: false,
      key: "zapier",
    },
  ]);
  const [activeBtn, setActiveBtn] = useState("Flows");

  const [initialPermissions, setInitialPermissions] = useState({
    Analytics: false,
    dashboard: false,
    logs: false,
    chat_account: false,
    billing: false,
    zapier: false,
    ui_builder: false,
    styles: false,
    reports: false,
    notifications: false,
    node_builder: false,
  });

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );

    // Update the initialPermissions object when a checkbox is clicked
    setInitialPermissions((prevInitialPermissions) => {
      const updatedInitialPermissions = { ...prevInitialPermissions };
      checkboxes.forEach((checkbox) => {
        updatedInitialPermissions[checkbox.key] = checkbox.checked;
      });
      return updatedInitialPermissions;
    });
  };

  const handleColor = (value) => {
    value.isActive = true;
    setActiveBtn(value.name);
    const update = [...list];
    update.map((val, ind) => {
      if (val.id === value.id) {
        val.isActive = true;
      } else {
        val.isActive = false;
      }
    });
    setList(update);
  };
  const handleRemoveItems = (index) => {
    const temp = [...item];
    temp.splice(index, 1);
    setItem(temp);
  };
  const handleRemoveAccount = (index) => {
    const temp = [...account];
    temp.splice(index, 1);
    setAccont(temp);
  };
  const handleRemoveGroups = (index) => {
    const temp = [...groups];
    temp.splice(index, 1);
    setGroups(temp);
  };

  const handleSelectOnChangeFlows = (e) => {
    const id = e.target.value;
    const name = allFlows.find((item) => item.id === id).name;
    item.includes(e.target.value) ? "" : item.push({ id, name });
    setAddNewUser((prev) => ({ ...prev, choose: e.target.value }));
  };
  const handleSelectOnChangeAccounts = (e) => {
    const id = e.target.value;
    const name = allAccounts.find((item) => item.id === id).name;
    account.includes(e.target.value) ? "" : account.push({ id, name });
    setAddNewUser((prev) => ({ ...prev, choose: e.target.value }));
  };
  const handleSelectOnChangeGroups = (e) => {
    const id = e.target.value;
    const name = allGroups.find((item) => item.id === id).name;
    groups.includes(e.target.value) ? "" : groups.push({ id, name });
    setAddNewUser((prev) => ({ ...prev, choose: e.target.value }));
  };

  const handleOptionChange = (e) => {
    if (activeBtn === "Flows") {
      setSelectedOption(e.target.value);
    } else if (activeBtn === "Accounts") {
      setTypeAccont(e.target.value);
    } else {
      setTypeGroup(e.target.value);
    }
  };

  useEffect(() => {
    setNewAccount({
      ...newAccount,
      disabled: toggleIsActive === true ? false : true,
      is_admin: state,
      permissions: {
        ...newAccount.permissions,
        flows: {
          ...newAccount.permissions.flows,
          type: "READ_AND_WRITE",
          access: item.map((item) => item.id),
        },
        accounts: {
          type: typeAccounts,
          access: account?.map((item) => item.id),
        },
        groups: {
          type: typeGroups,
          access: groups?.map((item) => item.id),
        },
        Analytics: initialPermissions.Analytics,
        dashboard: initialPermissions.dashboard,
        logs: initialPermissions.logs,
        chat_account: initialPermissions.chat_account,
        billing: initialPermissions.billing,
        zapier: initialPermissions.zapier,
        ui_builder: initialPermissions.ui_builder,
        styles: initialPermissions.styles,
        reports: initialPermissions.reports,
        notifications: initialPermissions.notifications,
        node_builder: initialPermissions.node_builder,
      },
    });
  }, [
    state,
    toggleIsActive,
    item,
    groups?.length,
    account?.length,
    typeGroups,
    typeAccounts,
    selectedOption,
    initialPermissions,
  ]);

  const handleNewAccounts = () => {
    createUser(newAccount)
      .then((res) => {
        if (res) {
          showSuccessMessage("Contact has been added");
          setAddNewUser(false);
          setSelected(res);
          getAllUsersData("update");
        } else {
          showErrorMessage("Unable to add contact");
        }
      })
      .catch((err) => {
        showErrorMessage("Unable to add contact");
        console.log({ err });
      });
  };
  const dropdownStyle = {};

  return (
    <>
      <div className="custom-container2">
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-row justify-content-between w-100 gap-4 px-4 pt-4">
            <div className="d-flex flex-column w-100">
              <CustomInput
                label={"Name"}
                labelClass={" full-field-title-name"}
                placeholder={"Enter Name"}
                inputStyle={"input-custom-add h-5"}
                onChange={(e) =>
                  setNewAccount((prev) => ({
                    ...prev,
                    full_name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="d-flex flex-column w-100">
              <div className="full-field-title-edit mb-0">Phone Number</div>
              <div className="phone-input-div">
              <PhoneInput
                    country={"in"}
                    enableSearch={true}
                    dropdownStyle={dropdownStyle}
                    inputProps={{
                      name: "",
                      required: true,
                    }}
                    onChange={(phone, country) => {
                      let country_id = parseInt(dailCode.current);
                      dailCode.current = country.dialCode;
                      let number = parseInt(
                        phone.slice(dailCode.current.length)
                      );
                      setNewAccount((prev) => ({
                        ...prev,
                        phone_number: {
                          number: number,
                          country_code: country_id,
                        },
                      }));
                    }}
                  />
                {/* <div className="select-input-div">
                  <CustomSelect
                    className="half-select-phone"
                    options={countryCodeOptions}
                    onChange={(e) =>
                      setNewAccount((prev) => ({
                        ...prev,
                        phone_number: {
                          ...prev.phone_number,
                          country_code: e.target.value,
                        },
                      }))
                    }
                  />
                
                  <div className="customPhoneNUmberInput">
                    <CustomInput
                      onChange={(e) =>
                        setNewAccount((prev) => ({
                          ...prev,
                          phone_number: {
                            ...prev.phone_number,
                            number: e.target.value,
                          },
                        }))
                      }
                      placeholder="9876543210"
                      numericOnly
                      inputStyle="half-field-phone"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between w-100 mt-4 gap-4 p-4 pt-0">
            <div className="d-flex flex-column w-100">
              <CustomInput
                label={"Email"}
                labelClass={" full-field-title-edit"}
                placeholder={"Abc@gmail.com"}
                inputStyle={"input-custom-add h-5"}
                onChange={(e) =>
                  setNewAccount((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
            </div>

            <div className="d-flex flex-column w-100">
              <CustomInput
                type="file"
                id="file"
                label={"Upload Profile"}
                labelClass={" full-field-title-edit"}
                placeholder={"Choose A Picture"}
                onChange={handleChangeIcon}
                uploadLabel="Upload Profile"
              />
            </div>
          </div>
          <div className="bottom-sec-edit-2 p-4">
            <div className="bottom-sec-div">
              <p className="bottom-heading2">Account status</p>
              <div className="d-flex flex-row bottom-div1">
                <div>
                  <label class="switch">
                    <input
                      checked={toggleIsActive}
                      type="checkbox"
                      defaultValue={toggleIsActive}
                      onClick={() => setToggleIsActive(!toggleIsActive)}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <p className="bottom-role-title2">Active</p>
                  <p className="bottom-desc">
                    Account is currently active for use
                  </p>
               
                </div>
              </div>
            </div>
            <div className="bottom-sec-div">
              <p className="bottom-heading2">Admin Access</p>
              <div className="d-flex flex-row bottom-div1 ">
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      defaultValue={state}
                      onClick={() => setState(!state)}
                      checked={state}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                <div>
                  <p className="bottom-role-title2">Admin</p>
                  <p className="bottom-desc">Account is currently an admin</p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className=" bottom-div-edit">
              <div className="button-bar">
                {list.map((value, index) => {
                  return (
                    <button
                      key={value.id}
                      className="bar-btn"
                      onClick={() => handleColor(value)}
                      style={{
                        backgroundColor: value.isActive ? "#212429" : "#ffffff",
                        color: value.isActive ? "#dde2e5 " : "black",
                      }}
                    >
                      {value.name}
                    </button>
                  );
                })}
              </div>
              {activeBtn === "Others" ? (
                <div className="checkbox-container">
                  {checkboxes.map((checkbox) => (
                    <div key={checkbox.id} className="checkbox-item">
                      <input
                        type="checkbox"
                        checked={checkbox.checked}
                        onChange={() => handleCheckboxChange(checkbox.id)}
                        id={`checkbox-${checkbox.id}`}
                      />
                      <label htmlFor={`checkbox-${checkbox.id}`}>
                        {checkbox.label}
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="check-box-edit">
                    <label className="radio-button-label">
                      <input
                        type="radio"
                        value="Read"
                        checked={
                          activeBtn === "Flows"
                            ? selectedOption === "Read"
                            : activeBtn === "Accounts"
                            ? typeAccounts === "Read"
                            : typeGroups === "Read"
                        }
                        onChange={handleOptionChange}
                      />
                      <span className="radio-button-custom"></span>
                      Read
                    </label>
                    <label className="radio-button-label">
                      <input
                        type="radio"
                        value="Read and Write"
                        checked={
                          activeBtn === "Flows"
                            ? selectedOption === "Read and Write"
                            : activeBtn === "Accounts"
                            ? typeAccounts === "Read and Write"
                            : typeGroups === "Read and Write"
                        }
                        onChange={handleOptionChange}
                      />
                      <span className="radio-button-custom"></span>
                      Read and Write
                    </label>
                  </div>
                  <CustomSelect
                    title={"Select flows"}
                    titleClass={"full-field-title-edit"}
                    options={
                      activeBtn === "Flows"
                        ? allFlows
                        : activeBtn === "Accounts"
                        ? allAccounts
                        : allGroups
                    }
                    isDict
                    onChange={
                      activeBtn === "Flows"
                        ? handleSelectOnChangeFlows
                        : activeBtn === "Accounts"
                        ? handleSelectOnChangeAccounts
                        : handleSelectOnChangeGroups
                    }
                    className={"select-menu-edit"}
                    defaultValue={"Choose a value"}
                  />
                  <div className="item-btn-div">
                    {activeBtn === "Flows" ? (
                      <>
                        {item &&
                          item?.length > 0 &&
                          item?.map((val, index) => {
                            return (
                              <button className="item-btn2" key={index}>
                                <img
                                  className="remove-icon"
                                  src={RmvIcon}
                                  alt="icon"
                                  onClick={() => handleRemoveItems(index)}
                                />
                                {val?.name}
                              </button>
                            );
                          })}
                      </>
                    ) : activeBtn === "Accounts" ? (
                      <>
                        {account &&
                          account?.length > 0 &&
                          account?.map((val, index) => {
                            return (
                              <button className="item-btn2" key={index}>
                                <img
                                  className="remove-icon"
                                  src={RmvIcon}
                                  alt="icon"
                                  onClick={() => handleRemoveAccount(index)}
                                />
                                {val?.name}
                              </button>
                            );
                          })}
                      </>
                    ) : activeBtn === "Groups" ? (
                      <>
                        {groups &&
                          groups?.length > 0 &&
                          groups?.map((val, index) => {
                            return (
                              <button className="item-btn2" key={index}>
                                <img
                                  className="remove-icon"
                                  src={RmvIcon}
                                  alt="icon"
                                  onClick={() => handleRemoveGroups(index)}
                                />
                                {val?.name}
                              </button>
                            );
                          })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
            <CustomButton
              title={"Save details"}
              className={"builder-heading-btn-Save-details"}
              handleFunction={handleNewAccounts}
            />
          </div>
          </div>

      
        </div>
      </div>
    </>
  );
}
