import React, { useEffect, useState } from "react";
import Logo from "../../assets/idflow-logo.svg";
import Google from "../../assets/signUp/google-icon.svg";
import Outlook from "../../assets/signUp/outlook.svg";
import Facebook from "../../assets/signUp/facebook_outline.svg";
import { useNavigate, redirect } from "react-router-dom";
import "./login.css";
import { login, socialLogin } from "../../api/users";
import { setAccessToken, setEmail } from "../../api/common";
import CustomButton from "../core/Button";
import CustomInput from "../core/InputField";
import CustomHeader from "../core/Header";
import CustomFooter from "../core/Footer";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import { notification } from 'antd'
import '../../styles/global.css';
import Gleap
  from "gleap";
export default function LoginScreen() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [userDetails, setUserDetails] = useState({});
  const [api, contextHolder] = notification.useNotification();

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Some thing went wrong",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'
    })
  };
  const showSuccessMessage = () => {
    api.success({
      description: "User login successful!",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-success-message'
    })
  };


  const handleLogin = () => {
    if (loginData && loginData.email && loginData.password) {
      setLoader(true);
      login(loginData)
        .then((res) => {
          if (res) {
            showSuccessMessage();
            setAccessToken(res.token);
            setEmail(loginData.email);
            setUserDetails(res.user);

            Gleap.identify(userDetails?.email || "14247", {
              name: userDetails?.full_name,
              email: userDetails?.email,
              phone: "+1 (902) 123123",
              value: 199.95,
              customData: {
                plan: "Pro plan",
                company: "m2pfintech.com",
              },
            });

            setTimeout(() => {
              navigate("/home/explore");
            }, 2000);
          } else {
            showErrorMessage("Invalid email or password");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);

          showErrorMessage(
            "Unable to Login Please check email ID or Password",
            err
          );
        });
    } else if (loginData.email && loginData.password === '') {
      showErrorMessage("Please enter the password ");
    }
    else if (loginData.password && loginData.email === '') {
      showErrorMessage("Please enter Email id")
    }
    else {
      showErrorMessage("Please enter email ID and password");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

 const handleSocialLogin = async (user)=>{
try {
  
  let {url} = await socialLogin(user);
  
  window.location.replace(url);
} catch (error) {
console.log(error);
}

 }
  return (
    <div className="main-login">
      <IDFlowHeader />
      <div className="body-login">
        <div className="login-sec">
          <div>
            <CustomHeader
              title={"Welcome Back!"}
            />
            <div className="form_group_input">
              <CustomInput
                label={"Email ID"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                placeholder={"Enter your email"}
                handleKeyPress={handleKeyPress}
              />
            </div>
            <div className="form_group_input">
              <CustomInput
                label={"Password"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                setShowPassword={() => setShowPassword(!showPassword)}
                handleKeyPress={handleKeyPress}
                isIcon
              />
            </div>

            <p className="" onClick={() => navigate("/user/forget")}>
              Forgot Password?
            </p>
          </div>
          <div>
            <CustomButton handleFunction={handleLogin} title={"Login"} loader={loader} />
            <p className="btn-desc">
              Don't have an account?{" "}
              <span onClick={() => navigate("/user/signup")}>Sign up</span>
            </p>
            <div className="linediv">
              <p className="line" />
              <p className="linetext">or Login with</p>
              <p className="line" />
            </div>
            <div className="btn-div">
              <button onClick={async()=> await handleSocialLogin({auth_type: "GoogleOAuth"})}  className="icon-btn">
                <img src={Google} alt="icon" />
              </button>
              <button onClick={async ()=>{await handleSocialLogin({auth_type: "MicrosoftOAuth"})}} className="icon-btn">
                <img src={Outlook} alt="icon" />
              </button>
              {/* <button className="icon-btn">
                <img src={Facebook} alt="icon" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
