import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./index.css";

import ChatBtn from "../../assets/userScreenSvg/chat-btn.svg";
import RmvIcon from "../../assets/userScreenSvg/remove-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../core/InputField";
import CustomSelect from "../core/CustomSelect";
import { baseURLImage } from "../../api/common";

export default function EditUser({
  selected,
  setUpdateUser,
  allFlows,
  allAccounts,
  allGroups,
}) {
  const [state, setState] = useState(selected.is_admin);
  const [item, setItem] = useState(selected?.flows ? selected?.flows : []);
  const [account, setAccont] = useState(
    selected?.accounts ? selected?.accounts : []
  );
  const [groups, setGroups] = useState(
    selected?.groups ? selected?.groups : []
  );
  const [selectedOption, setSelectedOption] = useState(
    selected?.flowType ? selected?.flowType : "Read"
  );
  const [typeAccounts, setTypeAccont] = useState(
    selected?.groupType ? selected?.groupType : "Read"
  );
  const [typeGroups, setTypeGroup] = useState(
    selected?.accountType ? selected?.accountType : "Read"
  );

  const [activeBtn, setActiveBtn] = useState("Flows");
  const [toggleIsActive, setToggleIsActive] = useState(
    selected.disabled === false ? true : false
  );
  const [userPic, setUserPic] = useState(baseURLImage+selected.image);
  const [list, setList] = useState([
    {
      id: "1",
      name: "Flows",
      isActive: true,
    },
    {
      id: "2",
      name: "Accounts",
      isActive: false,
    },
    {
      id: "3",
      name: "Groups",
      isActive: false,
    },
    {
      id: "4",
      name: "Others",
      isActive: false,
    },
  ]);

  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      label: "Analytics",
      checked: selected?.permissions?.Analytics
        ? selected?.permissions?.Analytics
        : false,
      key: "Analytics",
    },
    {
      id: 2,
      label: "Billing",
      checked: selected?.permissions?.dashboard
        ? selected?.permissions?.dashboard
        : false,
      key: "billing",
    },
    {
      id: 3,
      label: "Logs",
      checked: selected?.permissions?.logs ? selected?.permissions?.logs : false,
      key: "logs",
    },
    {
      id: 4,
      label: "Dashboard",
      checked: selected?.permissions?.chat_account
        ? selected?.permissions?.chat_account
        : false,
      key: "dashboard",
    },
    {
      id: 5,
      label: "UI builder",
      checked: selected?.permissions?.billing
        ? selected?.permissions.billing
        : false,
      key: "ui_builder",
    },
    {
      id: 6,
      label: "Chat account",
      checked: selected?.permissions?.zapier
        ? selected?.permissions?.zapier
        : false,
      key: "chat_account",
    },
    {
      id: 7,
      label: "Styles",
      checked: selected?.permissions?.ui_builder
        ? selected?.permissions?.ui_builder
        : false,
      key: "styles",
    },
    {
      id: 8,
      label: "Reports",
      checked: selected?.permissions?.styles
        ? selected?.permissions?.styles
        : false,
      key: "reports",
    },
    {
      id: 9,
      label: "Notifications",
      checked: selected?.permissions?.reports
        ? selected?.permissions?.reports
        : false,
      key: "notifications",
    },
    {
      id: 10,
      label: "Node builder",
      checked: selected?.permissions?.notifications
        ? selected?.permissions?.notifications
        : false,
      key: "node_builder",
    },
    {
      id: 11,
      label: "Zapier",
      checked: selected?.permissions?.node_builder
        ? selected?.permissions?.node_builder
        : false,
      key: "zapier",
    },
  ]);
  const [initialPermissions, setInitialPermissions] = useState({
    Analytics: selected?.permissions?.Analytics
      ? selected?.permissions?.Analytics
      : false,
    dashboard: selected?.permissions?.dashboard
      ? selected?.permissions?.dashboard
      : false,
    logs: selected?.permissions?.logs ? selected?.permissions?.logs : false,
    chat_account: selected?.permissions?.chat_account
      ? selected?.permissions?.chat_account
      : false,
    billing: selected?.permissions?.billing
      ? selected?.permissions?.billing
      : false,
    zapier: selected?.permissions?.zapier
      ? selected?.permissions?.zapier
      : false,
    ui_builder: selected?.permissions?.ui_builder
      ? selected?.permissions?.ui_builder
      : false,
    styles: selected?.permissions?.styles
      ? selected?.permissions?.styles
      : false,
    reports: selected?.permissions?.reports
      ? selected?.permissions?.reports
      : false,
    notifications: selected?.permissions?.notifications
      ? selected?.permissions?.notifications
      : false,
    node_builder: selected?.permissions?.node_builder
      ? selected?.permissions?.node_builder
      : false,
  });

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );

    // Update the initialPermissions object when a checkbox is clicked
    setInitialPermissions((prevInitialPermissions) => {
      const updatedInitialPermissions = { ...prevInitialPermissions };
      checkboxes.forEach((checkbox) => {
        updatedInitialPermissions[checkbox.key] = checkbox.checked;
      });
      return updatedInitialPermissions;
    });
  };

  useEffect(() => {
    const {
      flows,
      flowType,
      accountType,
      accounts,
      groupType,
      groups,
      ...data
    } = selected;
    setUpdateUser({
      ...data,
      disabled: toggleIsActive === true ? false : true,
      is_admin: state,
      permissions: {
        ...data.permissions,
        flows: {
          type: selectedOption,
          access: item?.map((item) => item.id),
        },
        accounts: {
          type: typeAccounts,
          access: account?.map((item) => item.id),
        },
        groups: {
          type: typeGroups,
          access: groups?.map((item) => item.id),
        },
        Analytics: initialPermissions?.Analytics,
        dashboard: initialPermissions?.dashboard,
        logs: initialPermissions?.logs,
        chat_account: initialPermissions?.chat_account,
        billing: initialPermissions?.billing,
        zapier: initialPermissions?.zapier,
        ui_builder: initialPermissions?.ui_builder,
        styles: initialPermissions?.styles,
        reports: initialPermissions?.reports,
        notifications: initialPermissions?.notifications,
        node_builder: initialPermissions?.node_builder,
      },
    });
  }, [
    state,
    toggleIsActive,
    item,
    item?.length,
    account,
    account?.length,
    selectedOption,
    typeGroups,
    typeAccounts,
    groups,
    groups?.length,
    initialPermissions,
  ]);
  console.log({ initialPermissions });

  const handleColor = (value) => {
    value.isActive = true;
    setActiveBtn(value.name);
    const update = [...list];
    update.map((val, ind) => {
      if (val.id === value.id) {
        val.isActive = true;
      } else {
        val.isActive = false;
      }
    });
    setList(update);
  };
  const handleRemoveItems = (index) => {
    const temp = [...item];
    temp.splice(index, 1);
    setItem(temp);
  };
  const handleRemoveAccount = (index) => {
    const temp = [...account];
    temp.splice(index, 1);
    setAccont(temp);
  };
  const handleRemoveGroups = (index) => {
    const temp = [...groups];
    temp.splice(index, 1);
    setGroups(temp);
  };

  const handleSelectOnChangeFlows = (e) => {
    const id = e.target.value;
    const name = allFlows.find((item) => item.id === id).name;
    item.includes(e.target.value) ? "" : item.push({ id, name });
    setUpdateUser((prev) => ({ ...prev, choose: e.target.value }));
  };
  const handleSelectOnChangeAccounts = (e) => {
    const id = e.target.value;
    const name = allAccounts.find((item) => item.id === id).name;
    account.includes(e.target.value) ? "" : account.push({ id, name });
    setUpdateUser((prev) => ({ ...prev, choose: e.target.value }));
  };
  const handleSelectOnChangeGroups = (e) => {
    const id = e.target.value;
    const name = allGroups.find((item) => item.id === id).name;
    groups.includes(e.target.value) ? "" : groups.push({ id, name });
    setUpdateUser((prev) => ({ ...prev, choose: e.target.value }));
  };

  const handleOptionChange = (e) => {
    if (activeBtn === "Flows") {
      setSelectedOption(e.target.value);
    } else if (activeBtn === "Accounts") {
      setTypeAccont(e.target.value);
    } else {
      setTypeGroup(e.target.value);
    }
  };
  return (
    <>
      <div>
        <div className="d-flex flex-row justify-content-between p-5 sec2">
          <div>
            {/* 
            <input
              type="file"
              accept="image/*"
              name="profile_picture"
              onChange={handleFileChange}
            /> */}
            <img
              src={userPic}
              alt={selected.heading}
              width="45rem"
              height="45rem"
              className="rounded-img"
            />

            <h3 className="p-0 m-0 pt-3 headingText">{selected.full_name}</h3>
            <div className="d-flex flex-row pt-1 ">
              <p id="id-style">ID:{selected.id}</p>
              {selected.is_admin === true ? (
                <div className="role-div">Admin</div>
              ) : selected.is_admin === false ? (
                <div className="role-div">User</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="chat-btn-div">
            <img src={ChatBtn} alt="" />
          </div>
        </div>
      </div>

      <div className="p-5 custom-container2">
        <table>
          <tr>
            <th className="w-25 font_style p-0">Name</th>
            <td className="  p-0">
              <CustomInput
                placeholder={"Enter Name"}
                inputStyle={"input-field"}
                onChange={(e) =>
                  setUpdateUser((prev) => ({
                    ...prev,
                    full_name: e.target.value,
                  }))
                }
                defaultValue={selected.full_name}
              />
            </td>
          </tr>
          <tr>
            <th className="font_style  p-0">Country</th>
            <td className="  p-0">
              <CustomInput
                placeholder={"Country code"}
                inputStyle={"input-field"}
                onChange={(e) =>
                  setUpdateUser((prev) => ({
                    ...prev,
                    phone_number: {
                      ...prev.phone_number,
                      country_code: e.target.value,
                    },
                  }))
                }
                defaultValue={selected.phone_number?.country_code}
              />
            </td>
          </tr>
          <tr>
            <th className="font_style  p-0">Phone</th>
            <td className="  p-0">
              <CustomInput
                placeholder={"Phone no"}
                inputStyle={"input-field"}
                numericOnly
                onChange={(e) =>
                  setUpdateUser((prev) => ({
                    ...prev,
                    phone_number: {
                      ...prev.phone_number,
                      number: e.target.value,
                    },
                  }))
                }
                defaultValue={selected.phone_number?.number}
              />
            </td>
          </tr>

          <tr>
            <th className="font_style  p-0">Email</th>
            <td className="  p-0">
              <CustomInput
                placeholder={"Enter Email"}
                inputStyle={"input-field"}
                onChange={(e) =>
                  setUpdateUser((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                defaultValue={selected.email}
              />
            </td>
          </tr>
        </table>
      </div>
      <div className="bottom-sec-edit">
        <div className="bottom-sec-div">
          <p className="bottom-heading">Account status</p>
          <div className="d-flex flex-row bottom-div1">
            <div>
              <label class="switch">
                <input
                  checked={toggleIsActive}
                  type="checkbox"
                  defaultValue={toggleIsActive}
                  onClick={() => setToggleIsActive(!toggleIsActive)}
                />
                <span class="slider round"></span>
              </label>
            </div>
            <div>
              <p className="bottom-role-title">Active</p>
              <p className="bottom-desc">Account is currently active for use</p>
              <div className="bottom-btn-div">
                <button
                  onClick={() => setToggleIsActive(true)}
                  style={{
                    background: toggleIsActive ? "" : "none",
                    backgroundColor: toggleIsActive ? "#338A17" : "none",
                    color: toggleIsActive ? "white" : "black",
                  }}
                  className="bottom-btn1"
                >
                  Active
                </button>
                <button
                  onClick={() => setToggleIsActive(false)}
                  style={{
                    background: toggleIsActive ? "none" : "",
                    backgroundColor: toggleIsActive ? "none" : "#338A17",
                    color: toggleIsActive ? "black" : "white",
                  }}
                  className="bottom-btn2"
                >
                  Deavtive
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-sec-div">
          <p className="bottom-heading">Admin Access</p>
          <div className="d-flex flex-row bottom-div1 ">
            <div>
              <label class="switch">
                <input
                  type="checkbox"
                  defaultValue={state}
                  onClick={() => setState(!state)}
                  checked={state}
                />
                <span class="slider round"></span>
              </label>
            </div>

            <div>
              <p className="bottom-role-title">Admin</p>
              <p className="bottom-desc">Account is currently an admin</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className=" bottom-div-edit">
          <div className="button-bar">
            {list.map((value, index) => {
              return (
                <button
                  key={value.id}
                  className="bar-btn"
                  onClick={() => handleColor(value)}
                  style={{
                    backgroundColor: value.isActive ? "#212429" : "#ffffff",
                    color: value.isActive ? "#dde2e5 " : "black",
                  }}
                >
                  {value.name}
                </button>
              );
            })}
          </div>
          {activeBtn === "Others" ? (
            <div className="checkbox-container">
              {checkboxes.map((checkbox) => (
                <div key={checkbox.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    checked={checkbox.checked}
                    onChange={() => handleCheckboxChange(checkbox.id)}
                    id={`checkbox-${checkbox.id}`}
                  />
                  <label htmlFor={`checkbox-${checkbox.id}`}>
                    {checkbox.label}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="check-box-edit">
                <label className="radio-button-label">
                  <input
                    type="radio"
                    value="Read"
                    checked={
                      activeBtn === "Flows"
                        ? selectedOption === "Read"
                        : activeBtn === "Accounts"
                        ? typeAccounts === "Read"
                        : typeGroups === "Read"
                    }
                    onChange={handleOptionChange}
                  />
                  <span className="radio-button-custom"></span>
                  Read
                </label>
                <label className="radio-button-label">
                  <input
                    type="radio"
                    value="Read and Write"
                    checked={
                      activeBtn === "Flows"
                        ? selectedOption === "Read and Write"
                        : activeBtn === "Accounts"
                        ? typeAccounts === "Read and Write"
                        : typeGroups === "Read and Write"
                    }
                    onChange={handleOptionChange}
                  />
                  <span className="radio-button-custom"></span>
                  Read and Write
                </label>
              </div>
              <CustomSelect
                title={"Select flows"}
                titleClass={"full-field-title-edit"}
                options={
                  activeBtn === "Flows"
                    ? allFlows
                    : activeBtn === "Accounts"
                    ? allAccounts
                    : allGroups
                }
                isDict
                onChange={
                  activeBtn === "Flows"
                    ? handleSelectOnChangeFlows
                    : activeBtn === "Accounts"
                    ? handleSelectOnChangeAccounts
                    : handleSelectOnChangeGroups
                }
                className={"select-menu-edit"}
                defaultValue={"Choose a value"}
              />
              <div className="item-btn-div">
                {activeBtn === "Flows" ? (
                  <>
                    {item &&
                      item?.length > 0 &&
                      item?.map((val, index) => {
                        return (
                          <button className="item-btn" key={index}>
                            <img
                              className="remove-icon"
                              src={RmvIcon}
                              alt="icon"
                              onClick={() => handleRemoveItems(index)}
                            />
                            {val?.name}
                          </button>
                        );
                      })}
                  </>
                ) : activeBtn === "Accounts" ? (
                  <>
                    {account &&
                      account?.length > 0 &&
                      account?.map((val, index) => {
                        return (
                          <button className="item-btn" key={index}>
                            <img
                              className="remove-icon"
                              src={RmvIcon}
                              alt="icon"
                              onClick={() => handleRemoveAccount(index)}
                            />
                            {val?.name}
                          </button>
                        );
                      })}
                  </>
                ) : activeBtn === "Groups" ? (
                  <>
                    {groups &&
                      groups?.length > 0 &&
                      groups?.map((val, index) => {
                        return (
                          <button className="item-btn" key={index}>
                            <img
                              className="remove-icon"
                              src={RmvIcon}
                              alt="icon"
                              onClick={() => handleRemoveGroups(index)}
                            />
                            {val?.name}
                          </button>
                        );
                      })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
