import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Info from "../../../assets/userScreenSvg/info.svg";
import './inputs.css';
const CustomInput = ({
  label,
  onChange,
  placeholder,
  type = "text",
  isIcon = false,
  setShowPassword,
  labelClass,
  className,
  numericOnly,
  htmlFor,
  alphaOnly,
  defaultValue,
  isinfo = false,
  id,
  uploadLabel,
  disabled,
  length,
  value,
  handleKeyPress,
  error,
}) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    setFileName(e.target.files[0].name);
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <div className="formGroup">
      <label htmlFor={htmlFor} className={labelClass ? labelClass : "field-title-login"}>
        {isinfo ? (
          <>
            {label}
            <img src={Info} alt="info" className="ms-1" />
          </>
        ) : (
          label
        )}
      </label>
      {type !== "file" ? (
        <div className="w-100">
        <input
          id={id}
          value={value ? value : ""}
          onChange={onChange}
          className={`input-field ${className}`}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={length}
          // onKeyPress={
          //   numericOnly
          //     ? (e) => {
          //       const key = e.key;
          //       if (isNaN(key)) {
          //         e.preventDefault(); // Prevent entering non-numeric characters
          //       }
          //     }
          //     : null
          // }
          onKeyPress={handleKeyPress}
        // onKeyDown={
        //   alphaOnly ? (e) => {
        //     let charCode = e.keyCode;

        //     if ((charCode >= 65 && charCode <= 90) || charCode == 8 || charCode == 32) {
        //       null;
        //     } else {
        //       e.preventDefault();
        //     }
        //   } : null
        // }
        />
        {error ? <p className="formik_error">{error}</p> : null}
      </div>
      ) : (
        <div className="custom-file-upload">
          <span className="file-name">{fileName || placeholder}</span>
          <input
            id={id}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label htmlFor={id} className="custom-file-label">
            {uploadLabel ? uploadLabel : "Upload"}
          </label>
        </div>
      )}

      {isIcon && (
        <>
          {type === "password" ? (
            <FaEyeSlash
              className="password-toggle-icon"
              onClick={setShowPassword}
            />
          ) : (
            <FaEye className="password-toggle-icon" onClick={setShowPassword} />
          )}
        </>
      )}
    </div>
  );
};

export default CustomInput;
