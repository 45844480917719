import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/idflow-logo.svg";
import BgImage from "../../assets/signUp/bg-image.svg";

import { useNavigate } from "react-router-dom";
import "../loginScreen/login.css";
import CustomHeader from "../core/Header";
import CustomInput from "../core/InputField";
import CustomSelect from "../core/CustomSelect";
import CustomButton from "../core/Button";
import CustomFooter from "../core/Footer";
import "./index.css";
import { useLocation } from "react-router-dom";
import { checkUserRegister, createUser } from "../../api/users";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import CustomCheckBox from "../core/CustomCheckBox";
import TelSelect from "../core/TelSelect";
import { checkFormValidation } from "./validation";
import {notification} from 'antd'
import '../../styles/global.css';




export default function Register() {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [loader,setLoader] = useState(false);
  const [username, setUsername] = useState({
    firstName: "",
    lastName: "",
  });
  const [api, contextHolder] = notification.useNotification();
  const [isChecked, setIsChecked] = useState(false);
  const regex = /[a-zA-Z]+$/;
  const numRegex = /^[5-9]\d{9}$/;
  const options = [
    {
      label:"Organization",
      value:"Organization"
    },
    {
      label:"Individual",
      value:"Individual"
    }
  ];
  const serviceOption = [
    {
      label:"Bank",
      value:"Bank"
    },
    {
      label:"Fintech",
      value:"Fintech"
    },
    {
      label:"NBFC",
      value:"NBFC"
    },
    {
      label:"Insurance",
      value:"Insurance"
    },
    {
      label:"Education",
      value:"Education"
    },
    {
      label:"Others",
      value:"Others"
    }
  ];
  const dailCode = useRef();
  
  const location = useLocation();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    
    const email = searchParams.get("e");
    const decode = window.atob(email);
    setEmailAddress(decode);
    const token = searchParams.get("Id");
    if (email && token) {
      const body = {
        email,
        token,
      };
      checkUserRegister(body)
      .then((res) => {
        if (res && res.message === "verification successful") {
          showSuccessMessage(res?.message);
        } else {
          showErrorMessage();
          setTimeout(() => {
            navigate("/user/signup");
          }, 2000);
        }
      })
      .catch((err) => {
        showErrorMessage(err?.response?.data?.message);
        setTimeout(() => {
          navigate("/user/signup");
        }, 2000);
      });
    }
  }, []);
  
  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Email not verified",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'
    })
  };
  const showSuccessMessage = (success) => {
    api.info({
      description: success ? success : "Email verification Successful",
      placement:"bottomRight",
      duration: 2,
      className: 'toast-success-message'
    })
  };
  
    const [formDetails, setFormDetails] = useState({
      full_name: "",
      organization: "",//Fintech
      organization_name: "",
      phone_number: {
        country_code: 91,
        number: "",
      },
      email: "",
    service: "",//Bank
  });
  useEffect(() => {
    setFormDetails((prev) => ({
      ...prev,
      full_name: username.firstName + " " + username.lastName,
      email: emailAddress,
    }));
  }, [username, emailAddress]);
  const handleSignUp = () => {
    if (
      formDetails.email &&
      formDetails.full_name &&
      ((formDetails.organization !== "Individual" &&
        formDetails.organization_name) ||
        formDetails.organization === "Individual") &&
      formDetails.service &&
      formDetails.phone_number.country_code &&
      formDetails.phone_number.number &&
      username.firstName &&
      username.lastName
    ) {
      setLoader(true);
      if (regex.test(formDetails.full_name)) {
        if (formDetails.phone_number.country_code === 91) {
          if (numRegex.test(formDetails.phone_number.number)) {
            if (isChecked === true) {
              createUser(formDetails)
                .then((res) => {
                  setLoader(false);
                  if (res) {
                    showSuccessMessage(
                      "Set password Email sent to your account. Please check it."
                    );
                  } else {
                    showErrorMessage("User already exists with this email.");
                  }
                })
                .catch(() => {
                  setLoader(false);
                  showErrorMessage("Already user details Submitted Successfully.");
                });
            } else {
              setLoader(false);
              showErrorMessage("Please Agree to the Terms and Conditions");
            }
          } else {
            setLoader(false);
            showErrorMessage("Invalid number.");
          }
        } else {
          if (isChecked === true) {
            createUser(formDetails)
              .then((res) => {
                if (res) {
                  showSuccessMessage(
                    "Set password Email sent to your account. Please check it."
                  );
                } else {
                  showErrorMessage("User already exists with this email.");
                }
              })
              .catch(() => {
                showErrorMessage("Already user details Submitted Successfully.");
              });
          } else {
            showErrorMessage("Please Agree to the Terms and Conditions");
          }
          setLoader(false);
        }
      } else {
        setLoader(false);
        showErrorMessage("Name must contain alphabets.");
      }
    } else {
      setLoader(false);
      checkFormValidation({formDetails, username,showErrorMessage});
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignUp();
    }
  };

  const dropdownStyle = {};

  return (
    <div className="main">
      <div className="main-signupdetails">
        <IDFlowHeader/>
        <div className="body-signupdetails">
          {/* <img src={BgImage} className="register-bg" /> */}
          <div className="form-sec">
            <div>
              <CustomHeader
                title={"Digital Onboarding Platform"}
                description={
                  "We require a few more details from you to get started."
                }
              />
              <div className="field-div">
                <div className="form_group_input">
                  <CustomInput
                    label={"First Name"}
                    onChange={(e) =>
                      setUsername((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))
                    }
                    placeholder="Enter first name"
                    labelClass="field-titl"
                    alphaOnly="true"
                    handleKeyPress={handleKeyPress}
                  />
                </div>
                <div className="form_group_input">
                  <CustomInput
                    label={"Last Name"}
                    onChange={(e) =>
                      setUsername((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                    placeholder="Enter last name"
                    labelClass="field-titl"
                    alphaOnly="true"
                    handleKeyPress={handleKeyPress}
                  />
                </div>
              </div>
              <div className="form_group_input">
                <CustomSelect
                  label={"Sign up as"}
                  placeholder="Organization"
                  onChange={(e) =>
                    setFormDetails((prev) => ({
                      ...prev,
                      organization: e,
                    }))
                  }
                  options={options}
                  handleKeyPress={handleKeyPress}
                />
              </div>
              <div className="form_group_input">
                <CustomInput
                  label={"Organization Name"}
                  onChange={(e) =>
                    setFormDetails((prev) => ({
                      ...prev,
                      organization_name: e.target.value,
                    }))
                  }
                  placeholder="Enter your organization name"
                  handleKeyPress={handleKeyPress}
                />
              </div>
              <div className="form_group_input">
                <p className="full-field-title">Mobile Number</p>
                <TelSelect
                  country={"in"}
                  enableSearch={true}
                  dropdownStyle={dropdownStyle}
                  inputProps={{
                    name: "",
                    required: true,
                  }}
                  onChange={(phone, country) => {
                    let country_id = parseInt(dailCode.current);
                    dailCode.current = country.dialCode;
                    let number = parseInt(phone.slice(dailCode.current.length));
                    setFormDetails((prev) => ({
                      ...prev,
                      phone_number: {
                        number: number,
                        country_code: country_id,
                      },
                    }));
                  }}
                />
              </div>

              {/* <div className="select-input-register">
                <CustomSelect
                  className="half-select-phone"
                  options={countryCodeOptions}
                  onChange={(e) =>
                    setFormDetails((prev) => ({
                      ...prev,
                      phone_number: {
                        ...prev.phone_number,
                        country_code: e.target.value,
                      },
                    }))
                  }
                />

                <div className="customPhoneNUmberInput">
                  <CustomInput
                    length={10}
                    onChange={(e) =>
                      setFormDetails((prev) => ({
                        ...prev,
                        phone_number: {
                          ...prev.phone_number,
                          number: e.target.value,
                        },
                      }))
                    }
                    placeholder="9876543210"
                    numericOnly
                    inputStyle="half-field-phone"
                  />
                </div>
              </div> */}
              <div className="form_group_input">
                <CustomSelect
                  label={"How would like to use our services? "}
                  placeholder="Select service"
                  onChange={(e) =>
                    setFormDetails((prev) => ({ ...prev, service: e }))
                  }
                  options={serviceOption}
                  handleKeyPress={handleKeyPress}
                />
              </div>
              <div className="form_group_input">
                <CustomCheckBox
                  name="check"
                  label={
                    <div>I agree to the <span className="register-span">Terms and Conditions</span></div>
                  }
                  onChange={(e)=>{
                    setIsChecked(!isChecked);
                  }}
                  handleKeyPress={handleKeyPress}
                />
                {/* <div className="check-box-div">
                  <input
                    onChange={(e) => {
                      setIsChecked(!isChecked);
                    }}
                    type="checkbox"
                    required
                  />
                  <label className="labelstyle">
                    I Agree to the{" "}
                    <span className="register-span">Terms and Conditions</span>
                  </label>
                </div> */}
              </div>
            </div>
            <div>
              <CustomButton title={"Sign Up"} handleFunction={handleSignUp} loader={loader} />
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
