import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ConfirmSignUp from "./components/confirm";
import ForgetPasswordEmail from "./components/forgetPasswordEmail";
import ForgetScreen from "./components/forgetScreen";
import Integration from "./components/Integrations/Integrations";
import MetaDoc from "./components/Integrations/MetaDoc";
import LoginScreen from "./components/loginScreen";
import OneTimePassword from "./components/otp";
import PasswordScreen from "./components/passwordScreen";
import Profile from './components/profile';
import ChangePassword from './components/profile/changePassword';
import Register from "./components/register/register";
import SetPassword from "./components/setPassword";
import SignUp from "./components/signUp/signup";
import UsersScreen from "./components/usersScreen";

import ApiKeyManager from "./components/Integrations/apiKeyManager";
import CreateSecretKey from "./components/Integrations/CreateSecretKey";
import NotificationTemplate from './components/NotificationTemplate';
import SocialPageCall from './components/SocialLogCall';
import "./styles/global.css";

const DSN = "https://48ed957d34d342cbbbfe55ed9c4da998@o4505214857838592.ingest.sentry.io/4505584043884544";

Sentry.init({
  dsn: DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const path = (p) => "/user" + p;
const homePath = (h) => "/home" + h;
const router = createBrowserRouter([
  {
    path: homePath("/users/notifications"),
    element: <NotificationTemplate />
  },
  {
    path: homePath("/users/integration"),
    element: <Integration />
  },
  {
    path: homePath("/users/integration/CreateSecretKey/:id"),
    element: <CreateSecretKey />
  },
  {
    path: homePath("/users/integration/CreateSecretKey/view/:id"),
    element: <CreateSecretKey />
  },
  {
    path: homePath("/users/integration/apiKeyManager/view/:id"),
    element: <ApiKeyManager />
  },
  {
    path: homePath("/users/integration/apiKeyManager/:id"),
    element: <ApiKeyManager />
  },
  {
    path: homePath("/users/integration/Meta-doc"),
    element: <MetaDoc />
  },
  {
    path: homePath("/users/change-password"),
    element: <ChangePassword />
  },
  {
    path: homePath("/users/profile"),
    element: <Profile />
  },
  {
    path: path("/login"),
    element: <LoginScreen />,
  },
  {
    path: path("/otp"),
    element: <OneTimePassword />,
  },
  {
    path: path("/signup"),
    element: <SignUp />,
  },
  {
    path: path("/register"),
    element: <Register />,
  },
  {
    path: path("/confirm"),
    element: <ConfirmSignUp />,
  },
  {
    path: path("/setpassword"),
    element: <SetPassword />,
  },
  {
    path: path("/forget"),
    element: <ForgetPasswordEmail />,
  },
  {
    path: path("/forgetScreen"),
    element: <ForgetScreen />,
  },
  {
    path: path("/passwordscreen"),
    element: <PasswordScreen />,
  },
  {
    path: path(""),
    element: <SignUp />,
  },
  {
    path: homePath("/users/list"),
    element: <UsersScreen />,
  },
  {
    path: path("/social"),
    element: <SocialPageCall />,
  },
  {
    path: path("*"),
    element: <LoginScreen />,
  }
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
