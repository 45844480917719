import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CopyIcon from '../../assets/copy.svg';
import DeleteIcon from '../../assets/delete.svg';
import EditIcon from '../../assets/edit.svg';
import { copyToClipboard } from '../../util/helper';
import IntegrationHeader from '../HeadingInfoPanel';
import SearchBarInput from './SearchBar';

const ApiKeyManager = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const routeName = location.pathname;
    const isCustom = routeName.includes("custom");
  
    // const textAreaRef = useRef(null);
    // let LiRefs = []
    const showErrorMessage = (err) => {
        toast.error(err ? err : "Some thing went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
    };
    const showSuccessMessage = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
    });
    };
    
    const handleCopy = (textToCopy) => {
      copyToClipboard(textToCopy, showErrorMessage, showSuccessMessage);
    };


    let tableData = [
        {
            name: "First key",
            key: "{{secret.secret key}}",
            value: "Last Update: 04/10/2023"
        },
        {
            name: "key 2",
            key: "{{secret.secret key}}",
            value: "Last Update: 04/10/2023"
        },
        {
            name: "Final key",
            key: "{{secret.secret key}}",
            value: "Last Update: 04/10/2023"
        },
    ]

    let routeCustom = "/home/users/integration/CreateSecretKey";

    const handleEdit = (item) => { 
        // console.log(item, '->');
        item && navigate('/home/users/integration/CreateSecretKey/edit')
     }

  return (
        <>
            <IntegrationHeader 
                heading={"Meta Integration"}
                description={"Create and save your secret API Keys."}
                isBreadCrumb={"integration"}
                ButtonName={"Add New key"}
                btnNavigation={`${isCustom ? routeCustom+"/custom" : routeCustom  }`}
            />
            <div className="box-container d-inline-flex g-5 w-100 column px-4" style={{ height: '70vh'}}>
                <SearchBarInput
                className="w-50 mt-4"
                placeholder={"Search"}
                onChange={(e) => console.log(e.target.value)}
                // value={handleSearch}
                />
                <div className="cus-form m-0 mw-100 mt-3">
                    <div className="block-box">
                    <div className="table_mat_sec">
                        <table>
                            <thead>
                                <tr>
                                    <th className='cus-borderRight'>Name</th>
                                    <th className='cus-borderRight'>Key</th>
                                    {isCustom && <th className='cus-borderRight'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(tableData) && tableData?.map((item, i) => {
                                        return (
                                        <tr key={i}>
                                            <td className='p-3'>{item.name}</td>
                                            <td className='p-3'>
                                                {item.key}
                                                <button className="copy-btn float-end" onClick={() => handleCopy(item.name)}>
                                                    <img src={CopyIcon} alt="icon" />
                                                </button>
                                            </td>
                                            <td className='p-3 d-flex justify-content-between align-items-center'>
                                                <p className='m-0 text-gray'>{item.value}</p>
                                                <div className='float-end d-flex gap-3'>
                                                <button className="copy-btn" onClick={() => handleEdit(item?.name)}>
                                                    <img src={EditIcon} alt="icon" />
                                                </button>
                                                <button className="copy-btn">
                                                    <img src={DeleteIcon} alt="icon" />
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>

             <div className="box-container d-inline-flex g-5 w-100 column px-4" style={{ height: '70vh'}}>
                <IntegrationHeader
                    className={"bg-white"}
                    // hrLine={true}
                    SearchBar={true}
                    description={"Here is the list of API Secret Keys."}

                    >
                    <h1 style={{ fontSize: '20px'}}>Secret Key</h1>
                </IntegrationHeader>
                <div className="cus-form m-0 mw-100 mt-3 mx-4">
                    <div className="block-box">
                        {
                            Array.isArray(tableData) && tableData?.map((item, i) => {
                                return (
                                    <div className="secret-card">
                                        <h4 className='flex-fill w-25 m-0 d-flex align-items-center justify-content-center h-100 cus-borderRight'>Name</h4>
                                        <div className='flex-fill w-50 d-flex justify-content-between px-3 align-items-center h-100 cus-borderRight'>
                                            <p className='m-0 text-black'>{item.key}</p>
                                            <button className="copy-btn float-end" onClick={() => handleCopy(item.name)}>
                                                <img src={CopyIcon} alt="icon" />
                                            </button>
                                        </div>
                                        <div className='flex-fill w-50 d-flex align-items-center justify-content-between mx-4'>
                                            <p className='m-0 text-gray'>{item.value}</p>
                                            <div className='float-end d-flex gap-3'>
                                                <button className="copy-btn ">
                                                    <img src={EditIcon} alt="icon" />
                                                </button>
                                                <button className="copy-btn">
                                                    <img src={DeleteIcon} alt="icon" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApiKeyManager
