export const copyToClipboard = (
  textToCopy,
  showErrorMessage,
  showSuccessMessage
) => {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      showSuccessMessage("Text copied to clipboard: ");
    })
    .catch((error) => {
      showErrorMessage("Failed to copy text: ", error);
    });
};

export const ForgetMsg = ({ closeToast, toastProps }) => (
  // <div>
  //   <h6>Password is invalid</h6>
  //   <p>Password must contain</p>
  //   <p>1. The minimum password length is 8.</p>
  //   <p>2. one special character.</p>
  //   <p>3. One upper case and one lower case.</p>
  //   <p>4. Numbers</p><br/>
  // </div>
  <div>
    <h6>Password is invalid</h6>
    <p>Password must meet the following criteria:</p>
    <p>1. The minimum password length is 8 characters.</p>
    <p>2. It must contain at least one special character.</p>
    <p>3. It must have at least one uppercase letter and one lowercase letter.</p>
    <p>4. It should include at least one number.</p>
  </div>
)